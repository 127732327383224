import SHA256 from 'crypto-js/sha256';
import User from '../../models/User';

export default function useGTM(user, paymentMethods) {
    const reset = () => {
        window.dataLayer.push({ user_data: null, event_data: null, page_data: null });
    };

    const fetchUserData = () => {
        reset();

        const loginStatus = user ? 'logged' : 'anonym';
        const userData = { login_status: loginStatus };

        if (user) {
            const primaryHome = user.getPrimaryHome();

            userData.uid = user.get('id');
            userData.email = SHA256(user.get('email')).toString();
            userData.profile = user.getStatus();
            userData.verified_phone = user.isPhoneVerified();
            userData.verified_user = user.isVerified();
            userData.verified_home = user.hasVerifiedHome();
            userData.eligible_collection = user.isUserEligibleCollection();
            userData.level_home_completion_col = user.isCollection()
                ? user.getBestHomeRate(User.COLLECTION)
                : null;
            userData.level_home_completion_reg = user.getBestHomeRate(User.REGULAR);
            userData.country = primaryHome?.country();
            userData.subscription_renewal_status = user.isAutoRenew() ? 'auto_renew' : 'manual';
            userData.sponsored_user = user.get('has_godfather');
            // We don't have this information, I create a ticket (https://guesttoguest.atlassian.net/browse/MBS-471)
            userData.club_invitation = null;
            userData.loyalty_badge = parseInt(user.getLoyaltyLevel(), 10);
            // We don't have this information, I create a ticket (https://guesttoguest.atlassian.net/browse/MBS-472)
            userData.number_subscription_purchase = null;
            userData.number_exchanges = user.countExchanges();
            userData.number_homes = user.countHomes();
            userData.card_status = 'no_card';
        }

        if (paymentMethods && paymentMethods.stripe && paymentMethods.stripe.sources) {
            const defaultPayment = paymentMethods.stripe.sources.find(
                (payment) => paymentMethods.stripe.default_source === payment.get('id')
            );
            if (defaultPayment && defaultPayment.isExpired) {
                userData.card_status = defaultPayment.isExpired() ? 'card_expired' : 'card_valid';
            }
        }

        return userData;
    };

    const fetchEventData = (text, method) => {
        const eventData = { collection: false, text, method };

        eventData.collection =
            user?.getStatus() === User.COLLECTION_STATUS || location.pathname.includes('collection');

        return eventData;
    };

    const fetchPageData = () => ({
        location: location.href,
        referrer: document.referrer,
        title: document.title,
        language: navigator.language
    });

    const fetchPageView = (text = null, method = null) => {
        const userData = fetchUserData();

        return {
            user_data: userData,
            event_data: fetchEventData(text, method),
            page_data: fetchPageData()
        };
    };

    const fetchUserCreated = (text = null, method = null) => {
        reset();

        return {
            user_data: {
                uid: user.get('id'),
                email: SHA256(user.get('email')).toString(),
                profile: 'lead',
                sponsored_user: user.get('has_godfather'),
                club_invitation: user.hasGroup()
            },
            event_data: fetchEventData(text, method)
        };
    };

    const fetchAccount = (text = null, method = null) => {
        const userData = fetchUserData();

        return {
            user_data: {
                uid: userData.uid,
                email: userData.email,
                profile: userData.profile,
                verified_phone: userData.verified_phone,
                verified_user: userData.verified_user,
                verified_home: userData.verified_home,
                eligible_collection: userData.eligible_collection,
                level_home_completion_col: userData.level_home_completion_col,
                level_home_completion_reg: userData.level_home_completion_reg,
                sponsored_user: userData.sponsored_user,
                club_invitation: userData.club_invitation,
                number_subscription_purchase: userData.number_subscription_purchase
            },
            event_data: fetchEventData(text, method)
        };
    };

    const fetchHomeCreation = () => {
        // todo
    };

    const fetchFunnel = () => {
        // todo
    };

    const fetchSearchTrackingData = (calendar = {}) => {
        const { date_ranges: dateRange, flexibility, duration, last_minute: lastMinute } = calendar;

        const data = {
            searchParam: null,
            searchValue: null
        };

        if (dateRange && dateRange.length > 0) {
            if (flexibility) {
                data.searchParam = 'fixed_dates';
                switch (flexibility) {
                    case 1:
                        data.searchValue = '+/-1_day';
                        break;
                    case 3:
                        data.searchValue = '+/-3_days';
                        break;
                    case 7:
                        data.searchValue = '+/-7_days';
                        break;
                    default:
                        break;
                }
            } else if (lastMinute) {
                data.searchParam = 'last_minute';
            } else if (duration?.kind) {
                data.searchParam = 'flexible_dates';
                switch (duration.kind) {
                    case 'week-end':
                        data.searchValue = 'flexible_dates_weekend';
                        break;
                    case 'one-week':
                        data.searchValue = 'flexible_dates_1_week';
                        break;
                    case 'two-weeks':
                        data.searchValue = 'flexible_dates_2_weeks';
                        break;
                    case 'one-month':
                        data.searchValue = 'flexible_dates_1_month';
                        break;
                    default:
                        break;
                }
            } else {
                data.searchParam = 'fixed_dates';
                data.searchValue = 'exact_dates';
            }
        }

        return data;
    };

    const fetchFavorites = () => {
        // todo
    };

    const fetchMessaging = () => {
        // todo
    };

    const fetchExchanges = () => {
        // todo
    };

    const fetchNavigation = (action, area = null, text = null) => {
        const userData = fetchUserData();
        const eventData = fetchEventData();
        const page = document.querySelector('.page');
        const pageArea = page ? page.getAttribute('data-area') : null;

        const navigationData = {
            event_data: {
                action,
                collection: eventData.collection,
                area: area ? `${pageArea}_${area}` : pageArea,
                text
            },
            user_data: {
                login_status: userData.login_status
            }
        };

        if (userData.login_status === 'logged') {
            navigationData.user_data = {
                uid: userData.uid,
                email: userData.email,
                profile: userData.profile,
                verified_user: userData.verified_user,
                eligible_collection: userData.eligible_collection,
                level_home_completion_col: userData.level_home_completion_col,
                level_home_completion_reg: userData.level_home_completion_reg,
                number_subscription_purchase: userData.number_subscription_purchase,
                country: userData.country,
                number_exchanges: userData.number_exchanges
            };
        }

        return navigationData;
    };

    const fetchLead = () => {
        // todo
    };

    return {
        fetchUserData,
        fetchPageView,
        fetchUserCreated,
        fetchAccount,
        fetchHomeCreation,
        fetchFunnel,
        fetchSearchTrackingData,
        fetchFavorites,
        fetchMessaging,
        fetchExchanges,
        fetchNavigation,
        fetchLead
    };
}
