import React from 'react';
import HomeFake from '../components/home/HomeFake';

export default class UtilsMarvellous {
    static MAX_DISPLAYED_TILES = 8;
    static ADMIN_LEVEL = 'city';
    static INNER_RADIUS = 50;
    static OUTER_RADIUS = 300;

    static loadingTiles = Array.from(Array(UtilsMarvellous.MAX_DISPLAYED_TILES), (_, i) => (
        <HomeFake key={i} />
    ));

    static trackHomesViewed = (homes, component) => {
        // Event
        const event = new CustomEvent('event_dashboard', {
            detail: {
                component,
                show: homes.length > 0,
                homes: homes.map((item) => item.id)
            }
        });
        document.dispatchEvent(event);
    };

    static getPlace = (string = '') => {
        const comaIndex = string.indexOf(',');
        return comaIndex !== -1 ? string.substring(0, comaIndex) : string;
    };
}
