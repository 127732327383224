import Model from './Model';
import Home from './Home';
import Product from './Product';
import User from './User';
import Api from '../api/Api';
import Utils from '../utils/utils';

export default class Exchange extends Model {
    static INSURANCE_NEEDED = 1;
    static INSURANCE_NOT_NEEDED = 0;

    static TYPE_NORMAL = 1;
    static TYPE_MUTUAL = 2;

    static STATUS_DEFAULT = 0;
    static STATUS_PREAPPROVED = 1;
    static STATUS_ACCEPTED = 2;
    static STATUS_FINALIZED = 3;
    static STATUS_MODIFY = 4;
    static STATUS_GUEST_CANCELED = 5;
    static STATUS_HOST_CANCELED = 6;
    static STATUS_NOTATION = 7;
    static STATUS_DONE = 8;
    static STATUS_GUEST_NOTATION = 9;
    static STATUS_HOST_NOTATION = 10;

    static STATUS_GP_DEFAULT = 0;
    static STATUS_GP_CHOOSE = 1;
    static STATUS_GP_KEEP = 2;
    static STATUS_GP_GIVE = 3;

    static STATUS_FRAUD_DEFAULT = 0;
    static STATUS_FRAUD_SUSPECTED = 1;
    static STATUS_FRAUD_CONFIRMED = 2;
    static STATUS_FRAUD_CORRECTION = -1;

    static UPDATE_STATUS_REQUESTED = 1;
    static UPDATE_STATUS_CANCELLED = 2;
    static UPDATE_STATUS_REFUSED = 3;
    static UPDATE_STATUS_ACCEPTED = 4;
    static UPDATE_STATUS_NEED_BILLING = 5;
    static UPDATE_STATUS_FINALIZED = 6;
    static UPDATE_STATUS_OVERWRITTEN = 7;
    static UPDATE_STATUS_INCIDENT = 8;

    static CAUTION_NOT_PROCESSED = 1;
    static CAUTION_RETRIEVED = 2;
    static CAUTION_KEPT = 3;

    static COMMISSION_FACTOR = 0.035;

    // GP Promos constants
    static GP_PROMO_START_DATE = '2020-12-10';
    static GP_PROMO_NB_DAYS = 15;

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('start_on') && attributes.start_on) {
            attributes.start_on = Utils.getDayAtMidnightLocalTime(moment(attributes.start_on)).format();
        }

        if (attributes.hasOwnProperty('end_on') && attributes.end_on) {
            attributes.end_on = Utils.getDayAtMidnightLocalTime(moment(attributes.end_on)).format();
        }

        if (attributes.hasOwnProperty('home') && _.isObject(attributes.home)) {
            if (attributes.home instanceof Home === false) {
                attributes.home = new Home(attributes.home);
            }
        }

        if (attributes.hasOwnProperty('guest') && _.isObject(attributes.guest)) {
            if (attributes.guest instanceof User === false) {
                attributes.guest = new User(attributes.guest);
            }
        }

        if (attributes.hasOwnProperty('host') && _.isObject(attributes.host)) {
            if (attributes.host instanceof User === false) {
                attributes.host = new User(attributes.host);
            }
        }

        if (attributes.hasOwnProperty('user_annulation') && _.isObject(attributes.user_annulation)) {
            if (attributes.user_annulation instanceof User === false) {
                attributes.user_annulation = new User(attributes.user_annulation);
            }
        }

        if (attributes.hasOwnProperty('product_selected') && _.isObject(attributes.product_selected)) {
            if (attributes.product_selected instanceof Product === false) {
                attributes.product_selected = new Product(attributes.product_selected);
            }
        }

        return attributes;
    }

    getStatus() {
        switch (this.get('status')) {
            case Exchange.STATUS_DEFAULT:
                return 'default';
            case Exchange.STATUS_PREAPPROVED:
                return 'preapproved';
            case Exchange.STATUS_ACCEPTED:
                return 'accepted';
            case Exchange.STATUS_FINALIZED:
                return 'finalized';
            case Exchange.STATUS_MODIFY:
                return 'modify';
            case Exchange.STATUS_GUEST_CANCELED:
                return 'guest-canceled';
            case Exchange.STATUS_HOST_CANCELED:
                return 'host-canceled';
            case Exchange.STATUS_NOTATION:
                return 'notation';
            case Exchange.STATUS_DONE:
                return 'done';
            case Exchange.STATUS_GUEST_NOTATION:
                return 'guest-notation';
            case Exchange.STATUS_HOST_NOTATION:
                return 'host-notation';
            default:
                throw Error('Unknow exchange status.');
        }
    }

    isFilled() {
        return this.getMissingFields().length == 0;
    }

    getMissingFields() {
        const missing = [];
        if (!this.get('start_on')) {
            missing.push('start_on');
        }
        if (!this.get('end_on')) {
            missing.push('end_on');
        }
        if (!this.get('guest_nb')) {
            missing.push('guest_nb');
        }
        return missing;
    }

    getNightNb() {
        if (this.get('start_on') && this.get('end_on')) {
            return moment(this.get('end_on')).diff(moment(this.get('start_on')), 'days');
        }
        return 0;
    }

    getExchangeType(type) {
        switch (type) {
            case Exchange.TYPE_NORMAL:
                return 'guestpoint';
            case Exchange.TYPE_MUTUAL:
                return 'reciprocal';
            default:
                return 'guestpoint';
        }
    }

    getCommission() {
        return parseInt(this.get('home_deposit'), 10) * Exchange.COMMISSION_FACTOR;
    }

    getGpTotal(value = null) {
        if (value !== null && value !== undefined) {
            return value * this.getNightNb();
        }
        return this.get('guestpoint_amount') * this.getNightNb();
    }

    getGpDiscountMax() {
        return this.get('gp_balance') * this.get('max_possible_discount');
    }

    getGpDiscountTotal() {
        return this.get('additional_gp_discount') * this.get('gp_discount_price');
    }

    getGpFullPriceTotal() {
        return this.get('additional_gp_full_price') * this.get('gp_full_price');
    }

    isPackRequired() {
        return Boolean(this.get('is_pack_required'));
    }

    isInsuranceRequired() {
        return Boolean(this.get('insurance'));
    }

    getPackTotal() {
        return this.hasSelectedPack() ? this.get('product_selected').get('price') * this.getNightNb() : 0;
    }

    getSubscriptionTotal() {
        return this.hasSelectedSubscription() ? this.get('product_selected').get('price') : 0;
    }

    getInsuranceTotal() {
        return this.get('insurance_selected') ? this.get('insurance_selected') * this.getNightNb() : 0;
    }

    getAssistanceTotal() {
        return this.get('assistance') ? this.get('assistance') * this.getNightNb() : 0;
    }

    getTotalToPay(gpOnly = false, guestSubscriber = false) {
        if (guestSubscriber) {
            // Subscriber only pay for GuestPoints
            return this.getGpDiscountTotal() + this.getGpFullPriceTotal();
        }

        if (this.usesPackSystem()) {
            if (gpOnly) {
                return this.getGpDiscountTotal() + this.getGpFullPriceTotal();
            } else if (this.hasSelectedSubscription()) {
                return this.getSubscriptionTotal() + this.getGpDiscountTotal() + this.getGpFullPriceTotal();
            } else {
                return this.getPackTotal() + this.getGpDiscountTotal() + this.getGpFullPriceTotal();
            }
        } else {
            return (
                this.getCommission() +
                this.getInsuranceTotal() +
                this.getAssistanceTotal() +
                this.getGpDiscountTotal() +
                this.getGpFullPriceTotal()
            );
        }
    }

    hasToChooseOptions() {
        return (
            (this.get('insurance') == Exchange.INSURANCE_NEEDED && !this.get('insurance_selected')) ||
            this.get('assistance') === null ||
            this.get('assistance') === undefined
        );
    }

    isApprovable() {
        return (
            this.get('status') == Exchange.STATUS_DEFAULT &&
            this.isFilled() &&
            Boolean(this.get('home')) &&
            this.get('home').isPublished()
        );
    }

    isUnapprovable() {
        return this.get('status') > Exchange.STATUS_DEFAULT && this.get('status') < Exchange.STATUS_FINALIZED;
    }

    isApproved() {
        return this.get('status') >= Exchange.STATUS_PREAPPROVED;
    }

    isCancelable() {
        // exchange can be canceled if finalized and start today or in the future
        return (
            this.get('status') == Exchange.STATUS_FINALIZED &&
            moment(this.get('start_on')).isSameOrAfter(moment().format('YYYY-MM-DD'))
        );
    }

    isCanceled() {
        return (
            _.contains([Exchange.STATUS_HOST_CANCELED, Exchange.STATUS_GUEST_CANCELED], this.get('status')) ||
            (this.has('user_annulation') && Boolean(this.get('user_annulation').id)) ||
            (this.has('user_annulation_id') && Boolean(this.get('user_annulation_id')))
        );
    }

    isCanceledBy(userId) {
        return this.has('user_annulation') && this.get('user_annulation').id == userId;
    }

    isCanceledByGuest() {
        return this.isCanceledBy(this.get('guest').id);
    }

    isCanceledByHost() {
        return this.isCanceledBy(this.get('host').id);
    }

    isFinalized() {
        return this.get('status') >= Exchange.STATUS_FINALIZED;
    }

    isFinalizedOrCanceled() {
        return this.isFinalized() || this.isCanceled();
    }

    isStarted() {
        // exchange is started after the first night
        return this.isFinalized() && moment(this.get('start_on')).isBefore();
    }

    isFinished() {
        return this.isFinalized() && moment(this.get('end_on')).isSameOrBefore();
    }

    isFinishedOrCanceled() {
        return this.isFinished() || this.isCanceled();
    }

    hasDeposit() {
        return this.get('home_deposit') > 0;
    }

    hasUserToHandleDeposit(userId) {
        return this.hasToHandleDeposit() && this.get('host').id == userId;
    }

    hasToHandleDeposit() {
        return this.isFinished() && !this.isCanceled() && this.get('has_to_handle_deposit');
    }

    hasUserToHandleGuestpoints(userId) {
        return (
            this.isCanceled() &&
            this.has('user_annulation') &&
            this.get('user_annulation').id !== this.get('host').id &&
            !this.hasUserToHandleDeposit(userId) &&
            this.get('has_to_handle_gp') &&
            this.get('host').id == userId &&
            this.get('guestpoint_amount') > 0 &&
            this.get('status_fraud') != Exchange.STATUS_FRAUD_CONFIRMED
        );
    }

    hasToHandleGuestpoints() {
        return this.hasUserToHandleGuestpoints(Api.User.identity().id);
    }

    hasToRate() {
        return this.get('has_to_rate');
    }

    isRatedByUser(userId) {
        return (
            this.get('status') == Exchange.STATUS_DONE ||
            (this.get('status') == Exchange.STATUS_HOST_NOTATION && this.get('host').id == userId) ||
            (this.get('status') == Exchange.STATUS_GUEST_NOTATION && this.get('guest').id == userId)
        );
    }

    /**
     * Return true if a pack has been selected for this exchange
     */
    hasSelectedPack() {
        if (
            this.has('product_selected') &&
            this.get('product_selected').has('type') &&
            this.get('product_selected').get('type').label === Product.PACK_TYPE &&
            this.get('product_selected').get('price') > 0
        ) {
            return true;
        }
        return false;
    }

    /**
     * Return true if no pack nor subscription has been selected for this exchange
     */
    hasSelectedFreePack() {
        if (
            this.has('product_selected') &&
            this.get('product_selected').has('type') &&
            this.get('product_selected').get('type').label === Product.PACK_TYPE &&
            this.get('product_selected').get('price') == 0
        ) {
            return true;
        }
        return false;
    }

    /**
     * Return true if the exchange should use the pack system (rather than insurances)
     */
    usesPackSystem() {
        if (
            this.has('product_selected') &&
            this.get('product_selected').has('type') &&
            this.get('product_selected').get('type').label === Product.INSURANCE_TYPE
        ) {
            return false;
        }
        return true;
    }

    /**
     * Return true if the subscription has been selected
     */
    hasSelectedSubscription() {
        if (
            this.has('product_selected') &&
            this.get('product_selected').has('type') &&
            this.get('product_selected').get('type').label === Product.SUBSCRIPTION_TYPE
        ) {
            return true;
        }
        return false;
    }

    /**
     * Return an array of objects representing the details of what the user is paying
     */
    getProductsToPay(isSubscriber = false) {
        const products = [];
        const gpTotal = this.getGpDiscountTotal() + this.getGpFullPriceTotal();

        if (!isSubscriber) {
            if (this.hasSelectedSubscription()) {
                products.push({
                    type: 'subscription',
                    price: this.getSubscriptionTotal(),
                    deposit: this.getDeposit(),
                    order: 1
                });
            } else if (this.hasSelectedPack()) {
                products.push({
                    type: 'pay_as_you_go',
                    price: this.getPackTotal(),
                    deposit: this.getDeposit(),
                    nbNights: this.getNightNb(),
                    pricePerNight: this.get('product_selected').get('price'),
                    order: 2
                });
            }
        }

        if (gpTotal > 0) {
            products.push({
                type: 'gp',
                missingGP: this.get('additional_gp_discount') + this.get('additional_gp_full_price'),
                price: gpTotal,
                order: 3
            });
        }

        return products;
    }

    /**
     * Return an array of objects representing the details of what the user is paying
     */
    getEcommerceProducts(isSubscriber = false) {
        const products = [];

        if (!isSubscriber) {
            if (this.hasSelectedSubscription()) {
                products.push({
                    name: 'subscription',
                    price: this.getSubscriptionTotal(),
                    variant: 'new',
                    quantity: 1
                });
            } else if (this.hasSelectedPack()) {
                products.push({
                    name: 'pay_as_you_go',
                    price: this.get('product_selected').get('price'),
                    quantity: this.getNightNb()
                });
            }
        }

        if (this.getGpDiscountTotal() > 0) {
            products.push({
                name: 'gp',
                price: this.get('gp_discount_price'),
                quantity: this.get('additional_gp_discount')
            });
        }
        if (this.getGpFullPriceTotal() > 0) {
            products.push({
                name: 'gp',
                price: this.get('gp_full_price'),
                quantity: this.get('additional_gp_full_price')
            });
        }

        return products;
    }

    /**
     * Get the deposit value for service plus or old finalized exchanges.
     */
    getDeposit() {
        if (
            this.get('product_selected') !== null &&
            (this.get('product_selected').isPack() || this.get('product_selected').isSubscription())
        ) {
            return this.get('product_selected').get('deposit');
        } else {
            return this.get('home_deposit');
        }
    }
}
