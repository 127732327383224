import PropTypes from 'prop-types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Availability from '../../models/Availability';
import Analytics from '../../utils/analytics';
import Utils from '../../utils/utils';
import BlockCalendar from './BlockCalendar';
import CalendarSelectAvailabilitiesPopup from './utils/CalendarSelectAvailabilitiesPopup';

export default class EditCalendar extends BlockCalendar {
    static propTypes = {
        ...BlockCalendar.propTypes,
        setAvailability: PropTypes.func.isRequired,
        updateAvailabilities: PropTypes.func.isRequired,
        minimumNights: PropTypes.number
    };

    constructor(props) {
        super(props);

        this.selectAvailabilities = this.selectAvailabilities.bind(this);
    }

    /**
     * Add specific rules when drawing the calendar
     */
    customDrawCalendar(kalendaeOptions) {
        super.customDrawCalendar(kalendaeOptions);

        // On change, show or display the edit pop-up
        this.calendar.subscribe('change', (lastSelectedDate) => {
            const selectedDates = this.calendar.getSelectedRaw();

            this.hideEditPopup();

            if (selectedDates.length === 2) {
                this.displayEditPopup(lastSelectedDate);
            }
        });
    }

    /**
     * Overrides inherited function to keep hover on mouse out
     * @return {bool} false
     */
    hideHoverOnMouseout() {
        return false;
    }

    /**
     * Create the Edit Popup with Bootstrap popover
     * @param  {moment} lastSelectedDate Last selected date (the date to which we should append the popover)
     */
    displayEditPopup(lastSelectedDate) {
        const selectedDates = this.calendar.getSelectedRaw();
        const $dateSpan = $(this.calendar.container).find(
            `span.k-in-month[data-date="${lastSelectedDate.format(this.format)}"]`
        );
        const overlapsAvailability = this.countAvailabilities() > 0;

        let mergedPeriods = [];
        let type;

        this.props.availabilities
            .filter((d) => d.get('type') != Availability.BOOKED.type)
            .forEach((date) => {
                if (date.get('start_on').diff(selectedDates[1], 'days') == 1) {
                    type = date.get('type');
                    if (mergedPeriods.length === 0) {
                        // start
                        mergedPeriods.push(selectedDates[0]);
                        // end
                        mergedPeriods.push(date.get('end_on'));
                    } else {
                        mergedPeriods[1] = date.get('end_on');
                    }
                }
                if (date.get('end_on').diff(selectedDates[0], 'days') == -1) {
                    if (!type) {
                        type = date.get('type');
                    }
                    if (mergedPeriods.length === 0) {
                        // start
                        mergedPeriods.push(date.get('start_on'));
                        // end
                        mergedPeriods.push(selectedDates[1]);
                    } else {
                        mergedPeriods[0] = date.get('start_on');
                    }
                }
            });

        const content = document.createElement('div');
        if (mergedPeriods.length === 0) {
            mergedPeriods = null;
            type = null;
        }

        this.popover = $dateSpan
            .popover({
                html: true,
                placement: `auto ${this.computePopoverPosition($dateSpan)}`,
                content
            })
            .popover('show');
        createRoot(content).render(
            <CalendarSelectAvailabilitiesPopup
                popover={this.popover}
                dateRange={this.calendar.getSelectedRaw()}
                clickHandler={this.selectAvailabilities}
                mergedPeriods={mergedPeriods}
                type={type}
                overlapsAvailability={overlapsAvailability}
                minimumNights={this.props.minimumNights}
            />
        );
    }

    /**
     * Remove the edit pop-up if present
     */
    hideEditPopup() {
        if (this.popover) {
            this.popover.popover('destroy');
            this.popover = null;
            this.changeHandler();
        }
    }

    computePopoverPosition($elem) {
        const popoverEstimatedHeight = 85;
        const pageScroll = window.pageYOffset;
        const pageHeight = window.innerHeight;
        const elemTopOffset = $elem.offset().top;
        const elemHeight = $elem.outerHeight();

        if (pageScroll + pageHeight - (elemTopOffset + elemHeight) < popoverEstimatedHeight) {
            return 'top';
        }

        return 'bottom';
    }

    /**
     * Returns the number of availabitilies in the current selection (ignoring booked periods)
     * @return {integer} Number of availabilities in the current selection
     */
    countAvailabilities() {
        return this.getSelectedAvailabilities(true).length;
    }

    /**
     * Return an array of the availabilities in the current selection
     * @param  {Boolean} ignoreBooked If true, won't return the "BOOKED" periods
     * @return {Availabitilies[]} Array of availabilities in the current selection
     */
    getSelectedAvailabilities(ignoreBooked = false) {
        // Returns availabilities contained in the selection
        const selectedDates = this.calendar.getSelectedRaw();
        const selectedDatesRange = moment.range(selectedDates[0], selectedDates[1]);

        const availabilities = this.props.availabilities
            .filter((av) => av.get('type') !== Availability.OLYMPIC.type)
            .reduce((array, availability) => {
                const availabilityRange = moment.range(
                    availability.get('start_on'),
                    availability.get('end_on')
                );
                if (selectedDatesRange.overlaps(availabilityRange)) {
                    if (!ignoreBooked || availability.get('type') !== Availability.BOOKED.type) {
                        array.push(availability);
                    }
                }

                return array;
            }, []);

        return availabilities;
    }

    /**
     * Set disponibility on button click
     * @param  {string} type the type of availability to create
     */
    selectAvailabilities(type, joinedPeriods = null) {
        return () => {
            let startDate, endDate;
            if (joinedPeriods === null) {
                const selectedDates = this.calendar.getSelectedRaw();
                startDate = selectedDates[0];
                endDate = selectedDates[1];
            } else {
                startDate = joinedPeriods[0];
                endDate = joinedPeriods[1];
            }

            // Reorder start date and end date (start should be before end date)
            if (startDate.isAfter(endDate)) {
                const temp = endDate.clone();
                endDate = startDate;
                startDate = temp;
            }

            // Call API
            if (type != Availability.BOOKED.type) {
                this.props.setAvailability(
                    startDate.format('YYYY-MM-DD'),
                    endDate.format('YYYY-MM-DD'),
                    type
                );

                Analytics.trackGTM('HomeEdition', {
                    event_data: {
                        action: 'click',
                        text:
                            type === Availability.UNAVAILABLE.type
                                ? 'calendar_remove_availabilities'
                                : 'calendar_add_availabilities',
                        area: 'home edit'
                    }
                });
            }

            // Update the calendar
            this.displayNewAvailability(startDate, endDate, type);
            this.hideEditPopup();
            this.calendar.setSelected(null);
        };
    }

    /**
     * Add the availability to the calendar, handling overlapping
     * @param  {Date} startDate start date of the availability to add
     * @param  {Date} endDate end date of the availability to add
     * @param  {string} type type of availability
     */
    displayNewAvailability(startDate, endDate, type) {
        const selectedAvailabilities = this.getSelectedAvailabilities();
        let newAvailabilities = [];

        if (selectedAvailabilities.length === 0) {
            // Fix issue due to JSON serialization when creating a Model
            startDate = Utils.getDayAtMidnightUTC(startDate);
            endDate = Utils.getDayAtMidnightUTC(endDate);

            // Clone array
            newAvailabilities = this.props.availabilities.slice(0);
            newAvailabilities.push(
                new Availability({
                    start_on: startDate,
                    end_on: endDate,
                    type
                })
            );
        } else {
            const selectedRange = moment.range(startDate, endDate);

            // Add unimpacted availabilities
            this.props.availabilities.forEach((availability) => {
                let ignore = false;
                selectedAvailabilities.forEach((ignoredAvailability) => {
                    if (
                        ignoredAvailability.get('start_on') === availability.get('start_on') &&
                        ignoredAvailability.get('end_on') === availability.get('end_on')
                    ) {
                        // This availability is among the selectedAvailabilities, it might be impacted
                        ignore = true;
                    }
                });
                if (!ignore) {
                    newAvailabilities.push(availability);
                }
            });

            // Subtract all booked availabilities
            const selectedUnbookedRanges = this.parseAvailability(selectedAvailabilities, selectedRange);
            if (type !== Availability.UNAVAILABLE.type) {
                // Add the selected availability, parsed to avoid overlap with booked period
                selectedUnbookedRanges.forEach((range) => {
                    // Fix issue due to JSON serialization when creating a Model
                    const start = Utils.getDayAtMidnightUTC(range.start);
                    const end = Utils.getDayAtMidnightUTC(range.end);

                    newAvailabilities.push(
                        new Availability({
                            type,
                            start_on: start,
                            end_on: end
                        })
                    );
                });
            }

            newAvailabilities = newAvailabilities.concat(
                this.overrideAvailabilities(selectedAvailabilities, selectedUnbookedRanges)
            );
        }

        // Merge consecutive availabilities if same type
        newAvailabilities = this.mergeSameTypeAvailabilities(newAvailabilities);
        this.props.updateAvailabilities(newAvailabilities);
    }

    /**
     * Parse a range to remove booked availabilities, creating an array of ranges
     * @param  {Availability[]} availabilities All the current availabilities
     * @param  {moment.Range} selectedRange The range to parse
     * @return {moment.Range[]} An array of ranges that don't overlap any booked period
     *
     * Example:
     * CurrentCalendar [---BBBBB---]
     * UnbookedRanges  [-********--]
     * Result          [-**BBBBB*--]
     */
    parseAvailability(availabilities, selectedRange) {
        // Remove booked periods from selectedRange(return an array of ranges)
        let ranges = [selectedRange];

        availabilities.some((availability) => {
            if (availability.get('type') === Availability.BOOKED.type) {
                const availabilityRange = moment.range(
                    availability.get('start_on'),
                    availability.get('end_on')
                );
                const subtract = selectedRange.subtract(availabilityRange);
                if (subtract.length >= 2) {
                    ranges = [];
                    subtract.forEach((s) => {
                        ranges = ranges.concat(this.parseAvailability(availabilities, s));
                    });
                    // Don't go to next availability
                    return true;
                } else if (subtract.length === 1 && !subtract[0].isSame(selectedRange)) {
                    // There is only one subtract and it is different from the selection
                    // Example Current: [---BBB------]] Selected: [---******---] Result: [---BBB***---]
                    ranges = this.parseAvailability(availabilities, subtract[0]);

                    return true;
                }
            }
            return false;
        });

        return ranges;
    }

    /**
     * Get an array of availabilities overriding and reducing existing availabilities when needed
     * @param  {Availability[]} availabilities All the current availabilities
     * @param  {moment.Range[]} unbookedRanges Array of range that will override the availabilities
     * @return {Availability[]}                Array of new availabilities
     *
     * Example: The user selected a period to set in "GuestWanted"
     * CurrentCalendar [---AAAA--GGG---AAA---]
     * UnbookedRanges  [---AA************A---]
     * Result          [---AAGGGGGGGGGGGGA---]
     * Returns         [---AA------------A---] (the previous availabilities minus the new selected period)
     */
    overrideAvailabilities(availabilities, unbookedRanges) {
        const newAvailabilities = [];

        availabilities.forEach((availability) => {
            if (availability.get('type') === Availability.BOOKED.type) {
                newAvailabilities.push(availability);
            } else {
                unbookedRanges.forEach((range) => {
                    const availabilityRange = moment.range(
                        availability.get('start_on'),
                        availability.get('end_on')
                    );
                    if (range.contains(availabilityRange)) {
                        // The availability is completely contained in the range, remove it
                        return null;
                    } else if (range.overlaps(availabilityRange)) {
                        // The availability overlaps, only keep the part that is outside the unbookedRange
                        const subtract = availabilityRange.subtract(range);
                        subtract.forEach((r) => {
                            // Fix issue due to JSON serialization when creating a Model
                            const start = Utils.getDayAtMidnightUTC(r.start);
                            const end = Utils.getDayAtMidnightUTC(r.end);

                            newAvailabilities.push(
                                new Availability({
                                    type: availability.get('type'),
                                    start_on: start,
                                    end_on: end
                                })
                            );
                        });
                    }
                });
            }
        });

        return newAvailabilities;
    }

    /**
     * When two availabilities with the same type are consecutive, merge them
     * @param  {Availability[]} availabilities All the current availabilities
     * @return {Availability[]}                Array of new availabilities
     */
    mergeSameTypeAvailabilities(availabilities) {
        const newAvailabilities = availabilities.map((availability) => availability.clone());
        newAvailabilities.forEach((a1) => {
            newAvailabilities.forEach((a2, index) => {
                if (a1.get('type') !== Availability.BOOKED.type && a1.get('type') === a2.get('type')) {
                    if (a1.get('end_on').isSame(a2.get('start_on'))) {
                        // Create larger period
                        a1.set('end_on', a2.get('end_on'));
                        // Delete a2
                        newAvailabilities.splice(index, 1);
                    } else if (a1.get('start_on').isSame(a2.get('end_on'))) {
                        // Create larger period
                        a1.set('start_on', a2.get('start_on'));
                        // Delete a2
                        newAvailabilities.splice(index, 1);
                    }
                }
            });
        });

        return newAvailabilities;
    }

    /**
     * Check if the two arrays of availabilities are identical
     * @param  {Availability[]} currentAvailabilities
     * @param  {Availability[]} desiredAvailabilities
     * @return {bool} true if it has differences
     */
    static availabilitiesHasDifferences(currentAvailabilities, desiredAvailabilities) {
        if (desiredAvailabilities.length !== currentAvailabilities.length) {
            return true;
        }

        /* It has differences if:
         * - A desiredAvailability is not shown
         * - We display too many availabilities
         */
        let hasDifferences = false;
        const matchedCurrentAvailabilities = [];

        desiredAvailabilities.forEach((desiredAvailability) => {
            let matched = false;
            currentAvailabilities.forEach((currentAvailability) => {
                if (!matched && currentAvailability.isSame(desiredAvailability)) {
                    matched = true;
                    matchedCurrentAvailabilities.push(currentAvailability);
                }
            });

            if (!matched) {
                // Can't find this availability in the displayed ones
                hasDifferences = true;
            }
        });

        if (matchedCurrentAvailabilities.length !== currentAvailabilities.length) {
            // We display availabilities that are not in the database
            hasDifferences = true;
        }

        return hasDifferences;
    }
}
