import debounce from 'debounce-promise';

import Common from './Common';
import Utils from '../utils/utils';
import Storage from '../utils/Storage';
import { isFlexibleSearchFlagActive } from '../utils/features';

export default class Search {
    static requestOnce = debounce(Search.sendRequest, 200);
    static additionalRequestOnce = debounce(Search.sendRequest, 200);

    /*
     * Execute search query and abort a previous query if exists
     */
    static findHomes(filters, offset, limit, place, placeId, lastSearchId, isAdditional = false) {
        const request = isAdditional ? Search.additionalRequestOnce : Search.requestOnce;
        return request(filters, offset, limit, place, placeId, lastSearchId);
    }

    static sendRequest(filters, offset, limit, place, placeId, lastSearchId) {
        filters = filters || {};
        delete filters.placeId;
        filters = Utils.filterObjectRecursive(filters);

        let url = `/api/homes/search`;
        const params = $.param(
            Utils.filterObjectRecursive({
                offset,
                limit
            })
        );

        if (!_.isEmpty(params)) {
            url = `${url}?${params}`;
        }

        const lastSearchModel = { place };
        if (lastSearchId) {
            lastSearchModel.id = lastSearchId;
        }
        if (placeId) {
            lastSearchModel.place_id = placeId;
        }
        // eslint-disable-next-line camelcase
        const last_search = place ? lastSearchModel : null;
        // eslint-disable-next-line camelcase
        const data = last_search ? { last_search, search_query: filters } : { search_query: filters };

        const lastSearch = {
            id: lastSearchId,
            place
        };

        if (typeof placeId === 'string' && placeId !== 'false') {
            lastSearch.place_id = placeId;
        }

        return Common.request(
            {
                method: 'POST',
                url,
                auth: true,
                data: JSON.stringify(data),
                dataType: 'json',
                contentType: 'application/json',
                cache: false
            },
            true,
            true
        );
    }

    static getLastResearches(nbResearches = 1) {
        return Common.request(
            {
                url: `/v1/search/last${isFlexibleSearchFlagActive() ? '?version=2' : ''}`
            },
            null,
            null,
            nbResearches
        );
    }

    static getLastSearch() {
        const params = Common.params(
            {
                url: '/v1/search/last',
                method: 'get'
            },
            null,
            null,
            1
        );
        return Common.request(params).then((results) => {
            if (Array.isArray(results) && results.length > 0) {
                return results[0];
            }
            return null;
        });
    }

    static setLastSearch(userId, data) {
        data.query = data.query ? data.query : 'everywhere';
        Storage.set(`${userId}_search`, data);
    }

    static getLocalLastSearch(userId) {
        const deferred = $.Deferred();
        deferred.resolve(Storage.get(`${userId}_search`));
        return deferred.promise();
    }
}
