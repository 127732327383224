/* global intlTelInputGlobals */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import i18n, { locales } from '../../i18n';
import intlTel from '../../utils/intlTel';

export default class TelInput extends React.PureComponent {
    telInput;

    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.string,
        required: PropTypes.bool,
        onChange: PropTypes.func,
        requiredMessage: PropTypes.string,
        invalidMessage: PropTypes.string,
        hasErrors: PropTypes.bool,
        signupCollection: PropTypes.bool
    };

    static defaultProps = {
        requiredMessage: i18n.t('fillingpanel:phone_required'),
        invalidMessage: i18n.t('fillingpanel:phone_invalid')
    };

    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.telInput = intlTelInput(this.input, {
            preferredCountries: this.getCountries(),
            initialCountry: this.getCountryCode(i18n.language),
            utilsScript: `https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/${intlTelInputGlobals.version}/js/utils.js`,
            separateDialCode: true
        });
        // format input value if is a valid number
        this.input.addEventListener('keyup', () => {
            if (this.telInput.isValidNumber()) {
                this.telInput.setNumber(this.telInput.getNumber());
            }
        });

        this.input.addEventListener('countrychange', () => {
            this.checkValidity();
        });

        this.checkValidity();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value && this.props.value !== prevProps.value && this.telInput) {
            this.telInput.setNumber(this.props.value);
            this.checkValidity();
        }
    }

    getCountries() {
        const countries = Object.values(locales).map((locale) => locale.substr(-2)); // get region part
        // add GB
        countries.push('GB');
        return countries;
    }

    getCountryCode(language) {
        const langToCountryCode = {
            da: 'dk',
            en: 'us',
            nb: 'no'
        };
        if (Object.keys(langToCountryCode).includes(language)) {
            return langToCountryCode[language];
        }
        return language;
    }

    checkValidity() {
        // check number validity
        if (this.telInput && !this.telInput.isValidNumber()) {
            if (this.input.value != '') {
                // display error if invalid
                this.setState({ error: 'invalid' });
            } else if (this.props.required) {
                // display error if required and empty
                this.setState({ error: 'empty' });
            } else {
                this.setState({ error: null });
            }
            return false;
        }
        this.setState({ error: null });
        return true;
    }

    onChange(e) {
        e.preventDefault();
        // check validity
        this.checkValidity();
        // call onChange property
        let phone = null;
        let phoneType = null;
        if (this.telInput.isValidNumber()) {
            phone = this.telInput.getNumber();
            phoneType = intlTel.getPhoneType(this.telInput);
        }
        this.props.onChange(phone, phoneType);
    }

    render() {
        return (
            <div
                className={classNames('form-group', {
                    'has-error': Boolean(this.state.error)
                })}
            >
                <input
                    type="tel"
                    id={this.props.id}
                    className={classNames('form-control', {
                        'form-entity-error': this.props.hasErrors
                    })}
                    required={this.props.required}
                    ref={(input) => {
                        this.input = input;
                    }}
                    defaultValue={this.props.value}
                    onChange={this.onChange}
                />
                <div
                    className={classNames('help-block help-empty', {
                        hide: this.state.error !== 'empty'
                    })}
                >
                    {this.props.requiredMessage}
                </div>
                <div
                    className={classNames('help-block help-invalid', {
                        hide: this.props.signupCollection ? true : this.state.error !== 'invalid'
                    })}
                >
                    {this.props.invalidMessage}
                </div>
            </div>
        );
    }
}
