let featureFlags = null;
const localFeatureFlags = {
    'exchange-modification-by-user': true,
    'flexible-search-2023': true,
    'search-map-auto-refresh': true,
    'surrounding-tags-2023': true,
    'olympic-games-push': true
};
const env = process.env.ENV;

const fetchFeatureFlagPromise = (url) => {
    return fetch(url).then((response) => {
        if (response.status === 404) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ code: 404 });
        }
        return response.json();
    });
};

function getUrl() {
    const domainName = window.location.hostname;
    const splitedDN = domainName.split('.');
    const envName = splitedDN[0] !== 'www' ? splitedDN[0] : 'staging';
    const stagingUrl = `https://d231x30y4w4ex.cloudfront.net/${
        envName === 'staging' ? '' : `${envName}.`
    }staging.json`;

    return stagingUrl;
}

const fetchFeatureFlag = () => {
    /** Production url is the default one */
    const defaultUrl = 'https://d1wyz6a07zol6e.cloudfront.net/features.json';

    switch (env) {
        case 'production':
            return fetchFeatureFlagPromise(defaultUrl)
                .then((featureFlag) => featureFlag.features)
                .catch(() => {
                    return {};
                });
        case 'preproduction':
            return fetchFeatureFlagPromise(getUrl())
                .then((featureFlag) => featureFlag.features)
                .catch((error) => {
                    console.error('[ERROR FETCHING FEATURE FLAG]: ', error);
                    if (error.code === 404) {
                        return fetchFeatureFlagPromise(defaultUrl)
                            .then((response) => {
                                return response;
                            })
                            .catch((e) => Promise.reject(e));
                    }
                });
        default:
            break;
    }
};

const getFeatureFlags = () => {
    if (!featureFlags) {
        if (process.env.NODE_ENV === 'test') {
            featureFlags = {};
            return false;
        }

        fetchFeatureFlag()
            .then((response) => {
                featureFlags = response;
            })
            .catch((e) => {
                console.error('[ERROR FETCHING FEATURE FLAG]: ', e);
                featureFlags = {};
            });
    }
};

if (env !== 'development') {
    getFeatureFlags();
}

/** Feature flag helper */
export const getFeatureFlag = (flagName) => {
    if (env === 'development' || process.env.NODE_ENV === 'test') {
        return localFeatureFlags[flagName] === true;
    }
    return featureFlags && featureFlags[flagName] === true;
};

// These helpers are used in the codebase to check if a feature flag is active or not
// They should be deleted when dismantling the feature

/** Modify an exchange feature flag helper */
export const isModifyExchangeFlagActive = () => {
    return getFeatureFlag('exchange-modification-by-user');
};

/** Flexible search feature flag helper */
export const isFlexibleSearchFlagActive = () => {
    return true;
};

/** Flexible search feature flag helper */
export const isSearchAutoRefreshFlagActive = () => {
    return getFeatureFlag('search-map-auto-refresh');
};

/** Surrounding tags feature flag helper */
export const isSurroundingTagsFlagActive = () => {
    return getFeatureFlag('surrounding-tags-2023');
};

/** Olympic games feature flag helper */
export const isOlympicGamesPushFlagActive = () => {
    return getFeatureFlag('olympic-games-push');
};
