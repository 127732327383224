import React, { Suspense } from 'react';

import i18n from '../../i18n';

const CreditCardFormFields = React.lazy(() => import('./CreditCardFormFields'));

// Map our fields with Stripe's
const creditCardAdditionalFieldsMapping = {
    'card-name': 'name',
    'card-address': 'address_line1',
    'card-zipcode': 'address_zip',
    'card-city': 'address_city',
    'card-country': 'address_country'
};
const creditCardAdditionalFields = Object.keys(creditCardAdditionalFieldsMapping);

const withPaymentFormTools = (WrappedComponent) =>
    class extends React.PureComponent {
        errors = {};

        constructor(props) {
            super(props);

            this.state = {
                errorMessage: null,
                formErrors: {},
                stripeElements: []
            };

            this.addStripeElement = this.addStripeElement.bind(this);
            this.setError = this.setError.bind(this);
            this.setFormError = this.setFormError.bind(this);
            this.handleError = this.handleError.bind(this);
            this.handleApiError = this.handleApiError.bind(this);
            this.handlePaypalError = this.handlePaypalError.bind(this);
            this.getTokenPromise = this.getTokenPromise.bind(this);
            this.onCreditCardFormFieldChange = this.onCreditCardFormFieldChange.bind(this);
            this.onCustomFieldChange = this.onCustomFieldChange.bind(this);
            this.onCustomFieldChangeThrottled = (e) => _.throttle(this.onCustomFieldChange(e), 200);
            this.validateField = this.validateField.bind(this);
            this.validateFields = this.validateFields.bind(this);
            this.listFormErrors = this.listFormErrors.bind(this);
            this.validateCreditCardForm = this.validateCreditCardForm.bind(this);
            this.clearFormErrors = this.clearFormErrors.bind(this);
            this.clearCreditCardForm = this.clearCreditCardForm.bind(this);

            this.creditCardFormFields = React.createRef();
        }

        addStripeElement(element) {
            this.setState({
                stripeElements: [...this.state.stripeElements, element]
            });
        }

        setError(error) {
            this.setState({
                errorMessage: error
            });
        }

        setFormError(property, error) {
            if (!error) {
                delete this.errors[property];
            } else {
                this.errors[property] = error;
            }
            this.setState({
                formErrors: Object.assign({}, this.errors)
            });
        }

        clearFormErrors() {
            this.setState({
                formErrors: {}
            });
            this.errors = {};
        }

        handleError(e) {
            if (e.type && e.type === 'validation_error') {
                // Validation errors shouldn't display a banner
                return;
            }

            let message;
            if (e.message) {
                message = i18n.t([`billing:${e.type}.${e.code}`, e.message]);
            } else {
                message = i18n.t('billing:error.generic-message', {
                    contact_start: `<a href="mailto:${i18n.t('common:contact-email')}">`,
                    contact_end: '</a>'
                });
            }

            this.setError(message);
        }

        getStripeErrorMessage(errorDetails) {
            let errorMessage = '';

            switch (errorDetails.code) {
                case 'card_declined':
                    if (errorDetails.decline_code === 'incorrect_zip') {
                        this.setFormError('card-zipcode', i18n.t('billing:error.incorrect-zip'));
                    }
                    errorMessage = i18n.t('billing:error.card-declined');
                    break;
                case 'expired_card':
                    errorMessage = i18n.t('billing:error.expired-card');
                    break;
                case 'incorrect_number':
                case 'invalid_number':
                    errorMessage = i18n.t('billing:error.invalid-number');
                    break;
                case 'invalid_expiry_year':
                case 'invalid_expiry_month':
                    errorMessage = i18n.t('billing:error.invalid-expiry');
                    break;
                case 'incorrect_cvc':
                case 'invalid_cvc':
                    errorMessage = i18n.t('billing:error.invalid-cvc');
                    break;
                case 'incorrect_address':
                    this.setFormError('card-address', i18n.t('billing:error.incorrect-address'));
                    break;
                case 'incorrect_zip':
                    this.setFormError('card-zipcode', i18n.t('billing:error.incorrect-zip'));
                    break;
                default:
                    errorMessage = '';
                    break;
            }

            return errorMessage;
        }

        handleApiError(payload) {
            let errorMessage;
            if (payload && payload.error) {
                let err = JSON.parse(JSON.stringify(payload.error));
                if (err && err.error) {
                    err = err.error;
                }

                if (payload.code && err.details) {
                    if (payload.code == 402 && err.details.code) {
                        // Stripe errors
                        errorMessage = this.getStripeErrorMessage(err.details);
                    } else if (payload.code == 409 && err.details.unlocks_in) {
                        errorMessage = i18n.t('billing:error.payment-locked');
                    }
                }
            }

            this.handleError(new Error(errorMessage));
        }

        handlePaypalError(err) {
            let message;
            if (err === 'payment-cancelled') {
                message = i18n.t('billing:error.paypal-cancelled');
            } else if (err === 'payment-error') {
                message = i18n.t('billing:error.paypal-error');
            } else if (err === 'component-creation-error') {
                message = i18n.t('billing:error.paypal-component-error');
            } else {
                message = i18n.t('billing:error.generic-message', {
                    contact_start: `<a href="mailto:${i18n.t('common:contact-email')}">`,
                    contact_end: '</a>'
                });
            }

            this.setError(message);
        }

        getTokenPromise(stripe, form) {
            return new Promise((res, rej) => {
                if (stripe) {
                    // Grab data for additional fields
                    const stripeAdditionalFields = {};
                    Array.prototype.slice
                        .call(form.current.elements)
                        .filter((input) => creditCardAdditionalFields.includes(input.name))
                        .forEach((input) => {
                            stripeAdditionalFields[creditCardAdditionalFieldsMapping[input.name]] =
                                input.value;
                        });

                    // Query Stripe to get token
                    stripe.createToken(stripeAdditionalFields).then(
                        (payload) => {
                            if (payload.token && payload.token.id) {
                                // for 3x payment
                                const expMonth = payload.token.card.exp_month;
                                const expYear = payload.token.card.exp_year;
                                const nbDaysOfMonth = moment(
                                    `${expYear}-${expMonth}`,
                                    'YYYY-MM'
                                ).daysInMonth();
                                const expiryDate = moment(
                                    `${expYear}-${expMonth}-${nbDaysOfMonth}`,
                                    'YYYY - MM - DD'
                                );
                                res({
                                    token: payload.token.id,
                                    expiryDate
                                });
                                // res(payload.token.id);
                            } else if (payload.hasOwnProperty('error')) {
                                rej(payload.error);
                            } else {
                                rej(new Error());
                            }
                        },
                        () => {
                            rej(new Error());
                        }
                    );
                } else {
                    rej(new Error());
                }
            });
        }

        onCreditCardFormFieldChange(fieldName) {
            return (e) => {
                if (e.error && e.error.message) {
                    this.setFormError(
                        fieldName,
                        i18n.t([`billing:${e.error.type}.${e.error.code}`, e.error.message])
                    );
                } else {
                    this.setFormError(fieldName, null);
                }
            };
        }

        onCustomFieldChange(fieldName) {
            return (e) => {
                this.validateField(fieldName, e.target.value);
            };
        }

        validateField(fieldName, fieldValue) {
            if (fieldValue.length === 0) {
                this.setFormError(
                    fieldName,
                    i18n.t([`billing:validation_error.${fieldName}.empty`, 'common:validation_error.empty'])
                );
            } else {
                this.setFormError(fieldName, null);
            }
        }

        validateFields(form) {
            // JS validation
            Array.prototype.slice
                .call(form.current.elements)
                .filter((input) => creditCardAdditionalFields.includes(input.name))
                .forEach((input) => {
                    this.validateField(input.name, input.value);
                });

            return this.errors;
        }

        validateStripe(stripe) {
            // Stripe doesn't provide validation functions, we need to try to create a token
            stripe.createToken();
        }

        listFormErrors(form) {
            // HTML5 validation
            Array.prototype.slice
                .call(form.current.elements)
                .filter((input) => input.checkValidity && !input.checkValidity())
                .forEach((input) => {
                    const message = input.validationMessage;
                    this.setFormError(input.name, message);
                });

            return this.errors;
        }

        validateCreditCardForm(stripe, form) {
            return new Promise((res, rej) => {
                // Validate fields
                this.listFormErrors(form);
                this.validateFields(form);
                this.validateStripe(stripe);

                if (Object.keys(this.errors).length > 0) {
                    return rej();
                }
                res();
            });
        }

        clearCreditCardForm(form) {
            const { stripeElements } = this.state;

            // Clear additional fields
            if (form && form.current) {
                form.current.reset();
            }

            // Clear select
            if (this.creditCardFormFields && this.creditCardFormFields.current) {
                if (
                    this.creditCardFormFields.current.countrySelect &&
                    this.creditCardFormFields.current.countrySelect.current &&
                    this.creditCardFormFields.current.countrySelect.current.select
                ) {
                    this.creditCardFormFields.current.countrySelect.current.select.clearValue();
                }
            }

            // Clear Stripe fields
            try {
                stripeElements.forEach((stripeElement) => {
                    stripeElement.clear();
                });
            } catch (error) {
                // Do nothing
            }
        }

        render() {
            const { errorMessage, formErrors, stripeElements } = this.state;

            const fields = (
                <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
                    <CreditCardFormFields
                        ref={this.creditCardFormFields}
                        errors={formErrors}
                        onFieldChange={this.onCreditCardFormFieldChange}
                        onCustomFieldChange={this.onCustomFieldChangeThrottled}
                        addStripeElement={this.addStripeElement}
                    />
                </Suspense>
            );

            return (
                <WrappedComponent
                    {...this.props}
                    fields={fields}
                    stripeElements={stripeElements}
                    validateCreditCardForm={this.validateCreditCardForm}
                    getTokenPromise={this.getTokenPromise}
                    clearCreditCardForm={this.clearCreditCardForm}
                    errorHandling={{
                        errorMessage,
                        formErrors,
                        listFormErrors: this.listFormErrors,
                        setFormError: this.setFormError,
                        clearFormErrors: this.clearFormErrors,
                        setError: this.setError,
                        handleError: this.handleError,
                        handleApiError: this.handleApiError,
                        handlePaypalError: this.handlePaypalError
                    }}
                />
            );
        }
    };

export default withPaymentFormTools;
