import React from 'react';
import dot from 'dot-object';
import Model from './Model';
import Filters from './Filters';
import i18n from '../i18n';
import Api from '../api/Api';
import Utils from '../utils/utils';
import { isFlexibleSearchFlagActive } from '../utils/features';

export default class Alert extends Model {
    static DEFAULT_PLACE = 'everywhere';

    static STATUS_DELETED = -1;
    static STATUS_DISABLED = 0;
    static STATUS_ENABLED = 1;

    static DESCRIPTION_MAX_LEN = 200;

    parse(attributes, options) {
        attributes = super.parse(attributes, options);
        if (attributes.hasOwnProperty('params') && _.isObject(attributes.params)) {
            attributes.params.place = _.has(attributes.params, 'place')
                ? attributes.params.place
                : Alert.DEFAULT_PLACE;
        }
        return attributes;
    }

    isOutdated() {
        const params = this.get('params');
        const dateFormat = _.has(params, 'date_format')
            ? Utils.convertPHPFormatMoment(params.date_format)
            : 'YYYY-MM-DD';
        if (_.has(params, 'home_end_on')) {
            const today = moment().startOf('day');
            const endOn = moment(params.home_end_on, dateFormat);
            return endOn.isSameOrBefore(today, 'day');
        }
        return false;
    }

    hasDates() {
        const params = this.get('params');
        return !(_.isEmpty(params.home_start_on) || _.isEmpty(params.home_end_on));
    }

    static fromSearch(query, filters) {
        filters = new Filters(filters);
        filters = filters.toJSON();
        // TODO: dynamize this when new alert API will be available
        let data = {
            collection: filters.collection,
            place: query,
            date_format: 'Y-m-d',
            home_capacity: filters.guests_nb ? String(filters.guests_nb) : null,
            guestpoints_from: filters.guestpoints.from,
            guestpoints_to: filters.guestpoints.to,
            home_type: filters.home.type,
            bedrooms: filters.home.size ? filters.home.size.bedrooms : null,
            bathrooms: filters.home.size ? filters.home.size.bathrooms : null,
            polygon: filters.location.polygon ? filters.location.polygon : null,
            bounds: filters.location.bounds
                ? [
                      filters.location.bounds.sw.lat,
                      filters.location.bounds.sw.lon,
                      filters.location.bounds.ne.lat,
                      filters.location.bounds.ne.lon
                  ].join(',')
                : null,
            country: filters.location.country ? filters.location.country : null,
            filter: _.union(filters.filters, filters.home.amenities),
            place_id: filters.placeId,
            beds:
                filters.home.size && filters.home.size.beds
                    ? {
                          adults: filters.home.size.beds.adults,
                          children: filters.home.size.beds.children,
                          babies: filters.home.size.beds.babies
                      }
                    : null
            // TODO: groups ?
        };

        if (isFlexibleSearchFlagActive() && filters.calendar) {
            data = {
                ...data,
                ...filters.calendar
            };
        } else {
            data = {
                ...data,
                home_start_on: filters.availability.date_range ? filters.availability.date_range.from : null,
                home_end_on: filters.availability.date_range ? filters.availability.date_range.to : null,
                home_flexibility: filters.availability.date_range
                    ? filters.availability.date_range.flexibility
                    : null,
                reciprocal: filters.availability.reciprocal
            };
        }
        if (filters.location.user) {
            const tmp = { user_filters: filters.location.user };
            data = Object.assign({}, data, dot.dot(tmp));
        }
        data = _.omit(
            data,
            (value) => _.isNull(value) || _.isUndefined(value) || (_.isObject(value) && _.isEmpty(value))
        );
        return new Alert({ params: data });
    }

    title() {
        const params = this.get('params');
        let title = [];
        let filters = {};

        if (params.place) {
            title.push(i18n.t(`search:${params.place}`));
        }

        if (params.date_format) {
            filters.date_format = params.date_format;
        }
        if (params.beds) {
            filters.home_capacity = params.home_capacity;
        }
        if (params.home_start_on) {
            filters.home_start_on = params.home_start_on;
        }
        if (params.home_end_on) {
            filters.home_end_on = params.home_end_on;
        }
        if (params.home_flexibility) {
            filters.home_flexibility = params.home_flexibility;
        }

        filters = Filters.fromParams(filters);
        filters = filters.stringify();
        title = _.union(title, filters);

        return title.join(', ');
    }

    url() {
        const filters = Filters.fromParams(this.get('params'));
        const params = `?${$.param(filters.params())}`;
        return (
            i18n.t('search:search_url', {
                slug: encodeURIComponent(this.get('params')?.place)
            }) + params
        );
    }

    save() {
        return Api.Alert.save(this)
            .done((response) => {
                this.set('id', response.id);
                const content = (
                    <div>
                        <p>${i18n.t('search:destination.created.text')}</p>
                        <p>
                            <a
                                href={`${i18n.t('user:user.edit.url', {
                                    id: window.gtg.userId
                                })}#preferred-destinations`}
                            >
                                {i18n.t('search:destination.created.link')}{' '}
                                <i className="fa fa-angle-right"></i>
                            </a>
                        </p>
                    </div>
                );
                sweetAlert({
                    icon: 'success',
                    title: i18n.t('search:destination.created.title'),
                    content
                });
            })
            .fail(() => {
                sweetAlert(
                    i18n.t('search:alert.create.failed.title'),
                    i18n.t('search:alert.create.failed.text'),
                    'error'
                );
            });
    }

    remove() {
        const deferred = $.Deferred();

        sweetAlert({
            title: i18n.t('search:alert.delete.title'),
            text: i18n.t('search:alert.delete.text'),
            icon: 'warning',
            buttons: {
                cancel: {
                    text: i18n.t('search:alert.delete.cancel'),
                    visible: true
                },
                confirm: {
                    text: i18n.t('search:alert.delete.confirm'),
                    closeModal: false
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
                Api.Alert.remove(this)
                    .done(() => {
                        deferred.resolve(this);
                        sweetAlert(
                            i18n.t('search:alert.deleted.title'),
                            i18n.t('search:alert.deleted.text'),
                            'success'
                        );
                    })
                    .fail((error) => {
                        deferred.reject(error);
                        sweetAlert(
                            i18n.t('search:alert.deleted.failed.title'),
                            i18n.t('search:alert.deleted.failed.text'),
                            'error'
                        );
                    });
            } else {
                deferred.reject('USER_CANCEL');
            }
        });

        return deferred;
    }

    toggle() {
        // Optimistic approach (we bet that it's going to succeed)
        const initialStatus = this.get('status');
        const status =
            this.get('status') == Alert.STATUS_DISABLED ? Alert.STATUS_ENABLED : Alert.STATUS_DISABLED;
        this.set('status', status);
        return Api.Alert.patch(this, { status }).fail(() => {
            this.set('status', initialStatus);
        });
    }

    static sortByDate(a, b) {
        const { home_end_on: dateA } = a.get('params');
        const { home_end_on: dateB } = b.get('params');
        if (!dateA && !dateB) {
            if (a.id <= b.id) {
                return 1;
            }
            return -1;
        }
        if (!dateB) {
            return -1;
        }
        if (!dateA) {
            return 1;
        }
        if (moment(dateA).isBefore(dateB)) {
            return -1;
        }
        if (moment(dateA).isSameOrAfter(dateB)) {
            return 1;
        }
        return 0;
    }
}
