import React, { useState, useEffect } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import i18n from '../../i18n';
import Utils from '../../utils/utils';
import Api from '../../api/Api';
import Home from '../../models/Home';
import HomeComponent from '../home/Home';
import CarouselComponent from '../common/carousel/carousel';
import UtilsMarvellous from '../../utils/utilsMarvellous';

const MIN_DISPLAYED_HOMES = 4;

const OlympicGames = () => {
    const [homes, setHomes] = useState([]);

    const fetchHomes = async () => {
        try {
            const data = await Api.Search.sendRequest(
                {
                    location: {
                        polygon: {
                            location_id: 'whosonfirst:macroregion:404227465',
                            provider: 'Jawg'
                        }
                    },
                    calendar: {
                        date_ranges: [
                            {
                                from: '2024-07-26',
                                to: '2024-08-11'
                            }
                        ],
                        duration: { number_of_nights: 1 }
                    }
                },
                0,
                UtilsMarvellous.MAX_DISPLAYED_TILES
            );

            if (data.homes?.length >= MIN_DISPLAYED_HOMES) {
                const homesList = data.homes.map((home, index) => {
                    const currentHome = new Home(home);
                    return (
                        <div key={currentHome.get('id')}>
                            <CSSTransitionGroup
                                transitionName="home-list-item"
                                transitionAppear={true}
                                transitionAppearTimeout={500 + 100 * index}
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={300}
                                component="div"
                            >
                                <HomeComponent home={currentHome} />
                            </CSSTransitionGroup>
                        </div>
                    );
                });
                setHomes(homesList);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!homes || homes.length > MIN_DISPLAYED_HOMES) {
            return;
        }
        fetchHomes();
    }, []);

    return (
        homes?.length > 0 && (
            <div className="container dashboard-module olympic-games dashboard-carousel">
                <CarouselComponent
                    classNameHeader="container container-header"
                    variableWidth={true}
                    displayHeader="in"
                    slides={homes}
                    title={i18n.t('dashboard:olympic-games.title')}
                    subtitle={i18n.t('dashboard:olympic-games.subtitle')}
                    additionalCTA={
                        <a
                            href={`${i18n.t('search:search_url', {
                                slug: 'Ile-of-France, France'
                            })}?location_id=whosonfirst:macroregion:404227465&provider=Jawg&number_of_nights=1&place_id=false&date_ranges=2024-07-26_2024-08-08`}
                            className="btn btn-primary"
                        >
                            {i18n.t('dashboard:collection-homes.button')}
                        </a>
                    }
                />
                <div>
                    <div className="leaf-background">
                        <img
                            alt="image-olympics"
                            className="hidden-xs hidden-sm hidden-md"
                            src={`${Utils.getCloudfrontDomain()}/images/website/home_page/olympics_back.svg`}
                        />
                        <img
                            alt="image-olympics"
                            className="hidden-lg"
                            src={`${Utils.getCloudfrontDomain()}/images/website/home_page/olympics_mobile.svg`}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default OlympicGames;
